<template>
    <section id="advantages" class="relative bg-home-advantages bg-cover bg-no-repeat h-full overflow-x-hidden pb-20">
        <img :src="PurpleShadowImg" class="absolute top-0 right-0" />
        <img :src="GreenBlueShadowImg" class="absolute bottom-0 left-0" />
        <div class="py-14 md:pt-20 px-6 md:px-14 2xl:px-40 3xl:px-[270px] relative">
            <div class="text-white text-center uppercase space-y-8">
                <h4 class="text-sm font-medium tracking-wider">
                    {{ $t('Share talents with the world and increase customer acquisition rate') }}
                </h4>
                <h1 class="text-4xl lg:text-5xl 2xl:text-[52px] font-light">
                    {{ $t('Advantages for talent providers') }}
                </h1>
            </div>
        </div>
        <div class="w-full relative px-6 md:ml-10 2xl:ml-40 3xl:ml-[270px] pb-10">
            <div class="hidden md:block swiper-container">
                <swiper
                    :scrollbar="{
                        hide: false,
                    }"
                    :space-between="20"
                    :modules="[Scrollbar, Mousewheel]"
                    :mousewheel="{
                        enabled: true,
                        forceToAxis: true,
                        thresholdDelta: 1,
                        thresholdTime: 1,
                    }"
                    :free-mode="true"
                    :slides-per-view="'auto'"
                    class="advantages-swiper 3xl:!pr-24 !pb-16">
                    <swiper-slide
                        v-for="advantage in advantages"
                        :key="advantage.title"
                        class="swiper-slide bg-black/30 text-white p-8 max-w-[312px] md:max-w-[350px] rounded-xl min-h-[37.5rem]">
                        <div class="swiper-slide-content">
                            <component :is="advantage.icon" class="h-12 w-12 mb-12" />
                            <h1 class="text-[26px] lg:text-[30px] 2xl:text-4xl mb-4 uppercase">
                                {{ $t(advantage.title) }}
                            </h1>
                            <p class="text-base mb-4">{{ $t(advantage.description) }}</p>
                            <hr v-if="advantage.subDescription" class="border border-dashed" />
                            <div v-for="(child, index) in advantage.subDescription" :key="index" class="text-sm mt-3">
                                <span v-if="child.title" :class="{'font-semibold': child.title && child.description}">
                                    {{ $t(child.title) }}
                                </span>
                                <span v-if="child.description"> - {{ $t(child.description) }}</span>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="flex md:hidden flex-col gap-3">
                <div
                    v-for="advantage in advantages"
                    :key="advantage.title"
                    class="bg-black/30 text-white p-8 min-w-[312px] rounded-xl">
                    <component :is="advantage.icon" class="h-12 w-12 mb-12" />
                    <h1 class="text-[26px] mb-4 uppercase">{{ $t(advantage.title) }}</h1>
                    <p class="text-base mb-4">{{ $t(advantage.description) }}</p>
                    <hr v-if="advantage.subDescription" class="border border-dashed" />
                    <div v-for="(child, index) in advantage.subDescription" :key="index" class="text-sm mt-3">
                        <span v-if="child.title" :class="{'font-semibold': child.title && child.description}">
                            {{ $t(child.title) }}
                        </span>
                        <span v-if="child.description"> - {{ $t(child.description) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-6 md:px-0 pb-20 md:pb-40 md:pt-14 2xl:pt-16 w-full flex justify-center relative">
            <InertiaLink
                :href="route('onboarding.index')"
                class="w-full md:w-auto font-medium cursor-pointer text-lg bg-transparent text-white border border-white rounded-full px-6 py-4 text-center hover:bg-white hover:text-base-content">
                <span class="text-lg 3xl:text-[20px] font-medium 2xl:font-semibold">
                    {{ $t('I’m ready to start') }}
                </span>
                &nbsp;
                <span class="text-base font-normal hidden md:inline">{{ $t('I want to share my talents') }}</span>
            </InertiaLink>
        </div>
    </section>
    <div class="w-full -mt-36 md:-mt-52 lg:-mt-[180px] relative">
        <div class="overflow-hidden relative z-20">
            <img src="@/images/frontend/advantages/advantages-ui.png" class="md:hidden block w-full ml-6" />
            <img
                src="@/images/frontend/advantages/advantages-ui-md.png"
                class="hidden md:block lg:hidden w-full ml-12" />
            <img
                src="@/images/frontend/advantages/advantages-ui-desktop.png"
                class="hidden lg:block mx-auto scale-75 xl:scale-100" />
        </div>
    </div>
</template>
<script setup lang="ts">
import {Link as InertiaLink} from '@inertiajs/vue3';
import GreenBlueShadowImg from '@/images/frontend/green-blue-shadow.png';
import PurpleShadowImg from '@/images/frontend/purple-shadow.png';
import {
    AdjustmentIcon,
    BadgeCheckIcon,
    DocumentIcon,
    DollarCircleIcon,
    GlobeIcon,
    LightningIcon,
    SmileyIcon,
    SwitchVerticalIcon,
} from '@/images/icons';
import 'swiper/css';
import 'swiper/css/scrollbar';
import {Mousewheel, Scrollbar} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/vue';

const advantages = [
    {
        icon: DollarCircleIcon,
        title: 'Cost & Revenue',
        description:
            'Monetize underutilized talent or expertise based on specialized technical skills, industry knowledge, or language proficiency creating new revenue streams.',
        subDescription: [
            {
                title: 'VS other solutions on market:',
                description: '',
            },
            {
                title: 'Recruitment agencies',
                description: 'take usually 20-30% fees on top of each contract signed.',
            },
            {
                title: 'Freelance platforms',
                description: 'fees added up to 30-70% of the freelancer rate.',
            },
        ],
    },
    {
        icon: LightningIcon,
        title: 'fast client connection',
        description:
            'Our system employs advanced matching algorithms and streamlined processes to connect clients with the ideal talent in record time.',
        subDescription: [
            {
                title: 'VS other solutions on market:',
                description: '',
            },
            {
                title: 'Recruitment agencies',
                description: 'the matching process is tedious and takes up to 3 months.',
            },
            {
                title: 'Freelance platforms',
                description:
                    'These platforms can provide a lot of prospects that end up not converting, wasting time and resources with communications.',
            },
        ],
    },
    {
        icon: SmileyIcon,
        title: 'build strong partnerships',
        description:
            'Our platform fosters collaboration and networking opportunities. By connecting companies and talent from around the world, it creates a thriving ecosystem where long-term partnerships can flourish. Regular interactions, shared project successes, and mutual trust contribute to building strong relationships that can lead to repeat business and referrals.',
    },
    {
        icon: AdjustmentIcon,
        title: 'Total ownership',
        description:
            'Our platform provides full control over talent management and project execution. We serve as a facilitator, connecting parties and streamlining processes without compromising ownership or control.',
    },
    {
        icon: DocumentIcon,
        title: 'Legal and contractual support',
        description:
            'Our platform offers standardized agreements that outline the terms and conditions for talent sharing, intellectual property rights, payment terms, and dispute resolution. This simplifies the legal process, reducing the time and cost associated with drafting and negotiating custom contracts',
    },
    {
        icon: BadgeCheckIcon,
        title: 'build your reputation',
        description:
            "By showcasing the company's ability to provide expert talent on demand, our scaling platform positions your organization as a valuable resource for businesses seeking specialized skills. This enhances the company's reputation as an innovative and solutions-oriented provider, attracting new clients based on the talent quality and feedback(reviews).",
    },
    {
        icon: GlobeIcon,
        title: 'Networking Opportunities',
        description:
            'The platform operates on a global scale, providing access to a vast pool of talent and project opportunities worldwide. This expansive reach enables costumers to find the exact skills they need, regardless of geographic location, and allows talent professionals to explore diverse career paths across different countries and cultures.',
    },
    {
        icon: SwitchVerticalIcon,
        title: 'Add Revenue Streams',
        description:
            'Monetize underutilized talent or expertise based on specialized technical skills, industry knowledge, or language proficiency creating new revenue streams without the overhead of traditional consulting or staffing services.',
    },
];
</script>
<style>
.advantages-swiper {
    @apply relative;
}

.advantages-swiper .swiper-scrollbar .swiper-scrollbar-drag {
    @apply bg-white rounded-xl;
}

.advantages-swiper .swiper-slide {
    @apply mr-5;
}
</style>
